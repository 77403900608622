import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.refreshChart()
  }

  params() {
    return {}
    // TODO: Use it when we'll have additional params in filter
    // return {
    //   timeline: App.helpers.get_param_value('acu_events[timeline]', '#acu-events-filter')
    // }
  }

  refreshChart() {
    $.post('/acu_events/chart.js', { asu_events_index: this.params() }, function(data) {
      eval(data)
    })
  }
}
