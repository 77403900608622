import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.cableConnection         = null;
    this.actioncableRequestTimer = null;

    const table   = $(this.element);
    const dataset = this.element.dataset;

    table.addClass('tetherx-bootstrap-table')

    let options = {
      locale:                I18n.locale,
      cookie:                true,
      showRefresh:           Cookies.get('selected_all_timelines') == 'true',
      cookiesEnabled:        [
        'bs.table.sortOrder',
        'bs.table.sortName',
        'bs.table.pageList',
        'bs.table.columns',
        'bs.table.searchText',
        'bs.table.filterControl',
        'bs.table.filterBy'
      ],
      icons: {
        clear: 'fa-trash',
        clearSearch: 'fa-trash',
        columns: 'fa-th-list',
        detailClose: 'fa-minus',
        detailOpen: 'fa-plus',
        filterControlSwitchHide: 'fa-search-minus',
        filterControlSwitchShow: 'fa-search-plus',
        fullscreen: 'fa-expand',
        paginationSwitchDown: 'fa-caret-square-down',
        paginationSwitchUp: 'fa-caret-square-up',
        refresh: 'fa-sync',
        search: 'fa-search',
        toggleOff: 'fa-toggle-off',
        toggleOn: 'fa-toggle-on'
      },
      search:                true,
      searchOnEnterKey:      false,
      showSearchButton:      false,
      filterControl:         true,
      showSearchClearButton: true,
      showFullscreen:        true,
      showColumns:           true,
      buttonsСlass:          'secondary',
      undefinedText:         'N/A',
      ajaxOptions:           { headers: { 'Bootstrap-Table': true } },
      toolbar:               dataset.toolbar,
      totalNotFilteredField: 'totalNotFiltered',
      onPostBody:            (data, a, b) => { this.onDataReceive(data, a, b) },
      rowStyle:              (data, _index) => { return { classes: data.custom_classes }},
      onLoadSuccess:         (data)         => { this.filterFields(table, data); table.bootstrapTable('hideLoading') },
      onSort:                ()             => { table.bootstrapTable('showLoading') }
    }

    // Actioncable connect. Do not connect in case if all timelines selected
    if (Cookies.get('selected_all_timelines') != 'true' && dataset.entityName) {
      // Fetch timeline id from cookies
      const timelineId = Cookies.get('timeline_id')

      // Detect when we need to reload table
      this.cableConnection = ActionCableConnection.subscribeTable(
        timelineId,
        dataset.entityName,
        ({ force }) => {
          if (force) {
            // If we need to refresh immediately (ex: on create)
            $(table).bootstrapTable('refresh', { silent: true })
          } else {
            // Send request once per 10 seconds to avoid request throttling
            if (this.actioncableRequestTimer) { return }

            this.actioncableRequestTimer = setTimeout(() => {
              this.actioncableRequestTimer = null // Clean variable
              $(table).bootstrapTable('refresh', { silent: true }) // Refresh users table
            }, is_test ? 1 : 10000) // 10 seconds
          }
        }
      );
    }

    if (dataset.pagination === 'true') {
      options = {
        ...options,
        ...{
          pagination: true,
          paginationUseIntermediate: true,
          pageSize: 50,
          pageList: [10, 25, 50, 100, 250, 500]
        }
      }
    }

    if (dataset.serverside === 'true') {
      options = {
        ...options,
        ...{
          url:             dataset.serversideUrl,
          sidePagination:  'server',
          loadingTemplate: this.loadingTemplate,
          showRefresh:     true,
          queryParams:     (params) => {
            // To pass parameters from filter, implement `bootstrapQueryParams` function on the page
            // and return needed hash there
            return typeof bootstrapQueryParams !== 'undefined' ? { ...params, ...bootstrapQueryParams() } : params
          },
          onColumnSwitch: () => {
            this.refresh(table)
          }
        }
      }
    }

    table.bootstrapTable(options)
  }

  disconnect() {
    if (this.cableConnection) {
      this.cableConnection.unsubscribe();
    }
  }

  reinitEventListeners() {
    // Refresh event listeners for table. Needs to pass table's selector
    const tableId        = this.element.getAttribute('id');
    const tableSignature = tableId ? `#${tableId}` : `.${this.element.getAttribute('class').split(' ').join('.')}`;

    App.helpers.refresh_listeners(tableSignature);
    App.helpers.dispatchFilterSubmitted();

    $(`#${tableId} select`).select2({
      sorter: (data) => {
        return data.sort((a, b) => {
          if (parseInt(a.text) > parseInt(b.text))
            return 1
          if (parseInt(a.text) < parseInt(b.text))
            return -1
          return 0
        })
      }
    })
  }

  addExportDropdown() {
    if ($('#export-bootstrapTable-dropdown')[0] || !this.element.dataset.exportUrl) { return }

    const onExportClick = (extension) => {
      const params = (typeof bootstrapQueryParams !== 'undefined') ? bootstrapQueryParams() : {};
      Api.post(`${this.element.dataset.exportUrl}.${extension}`, params)
    }

    const html = $(
      '<div id="export-bootstrapTable-dropdown" class="btn-group ml-2">' +
        '<button aria-expanded="false" class="btn btn-sm btn-primary dropdown-toggle" data-toggle="dropdown" type="button">' +
          '<i class="fas fa-share"></i> Export' +
        '</button>' +
        '<div class="dropdown-menu">' +
          '<a class="dropdown-item" data-extension="csv" data-target="#export_csv" data-toggle="modal" href="#">CSV</a>' +
          '<a class="dropdown-item" data-extension="xls" data-target="#export_xls" data-toggle="modal" href="#">Excel</a>' +
        '</div>' +
      '</div>'
    )

    $(html).find('a[data-extension="csv"]').click(() => { onExportClick('csv') });
    $(html).find('a[data-extension="xls"]').click(() => { onExportClick('xls') });

    $('.bs-bars').append(html)
  }

  loadingTemplate = () => {
    return "<div class='text-center' id='cc_spinner'>" +
             "<span class='loading'></span>" +
           "</div>"
  }

  refresh = (table) => {
    table.bootstrapTable('refresh')
  }

  onDataReceive(data) {
    this.reinitEventListeners();
    this.addExportDropdown();

    // If global `bootstrapOnDataReceive` defined --> call it. Needs to make custom
    // javascript to be executed on data load
    if (typeof bootstrapOnDataReceive == 'function') {
      bootstrapOnDataReceive(data);
    }
  }

  filterFields = (table, data) => {
    if (data && data.filterFields) {
      Object.keys(data.filterFields).map((el) => {
        let control = table.find(`.bootstrap-table-filter-control-${el}`)

        if (control.find('option').length !== (Object.keys(data.filterFields[el]).length + 1)) {
          control.empty()
          control.append('<option value="" selected="selected">All</option>')

          Object.keys(data.filterFields[el]).forEach((option) => {
            this.addOptionToSelectControl(control, option, data.filterFields[el][option])
          })
        }
      })
    }
  }

  addOptionToSelectControl = (selectControl, key, option) => {
    const value = option.toString().trim()
    selectControl.append(`<option value="${value}">${key}</option>`)
  }
}
