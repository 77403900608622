import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.load()
  }

  async load() {
    try {
      const response = await get(this.urlValue, {
        headers: { 'Accept': 'text/vnd.turbo-stream.html' }
      })
      if (response.ok) {
        const turboFrame = document.getElementById("previous_next")
        turboFrame.innerHTML = await response.text
      } else {
        console.error("Failed to load content", response)
      }
    } catch (error) {
      console.error("Error loading content", error)
    }
  }
}
