import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['zoneId', 'submitBtn', 'activeIn', 'activeOut']

  connect() {
    if (this.hasZoneIdTarget) {
      $(this.zoneIdTarget)
        .select2({ theme: 'bootstrap4', width: '100%' })
        .on('change', () => this.submitBtnTarget.classList.remove('hide'))
    }
  }

  onActiveInChange(e) {
    const checkbox = e.currentTarget.querySelector('input[type="checkbox"]')
    // Change switcher on UI
    checkbox.checked        = !checkbox.checked
    // Set new value to hidden field
    this.activeInTarget.value = checkbox.checked
    // Submit form
    $(e.currentTarget).closest('form').find('button[type="submit"]').click()
  }

  onActiveOutChange(e) {
    const checkbox = e.currentTarget.querySelector('input[type="checkbox"]')
    // Change switcher on UI
    checkbox.checked        = !checkbox.checked
    // Set new value to hidden field
    this.activeOutTarget.value = checkbox.checked
    // Submit form
    $(e.currentTarget).closest('form').find('button[type="submit"]').click()
  }
}
