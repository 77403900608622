import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('a').forEach((element) => {
      const turboFrame = document.querySelector(`turbo-frame[id="${element.dataset.turboFrame}"]`)

      if (turboFrame) {
        turboFrame.removeAttribute('src')
        element.click()
      }
    })
  }
}
