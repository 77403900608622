import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['accessControlId', 'fetchLink', 'storageUnitIds', 'paxtonPinCode', 'paxtonUserId']

  connect() {
    this.cableConnection = null;

    if (this.hasAccessControlIdTarget) {
      $(this.accessControlIdTarget)
        .select2({ theme: 'bootstrap4', width: '100%' })
        .on('change', this.refreshForm.bind(this))

      this.cableConnection = ActionCableConnection.subscribe('AccessControlChannel', this.accessControlIdTarget.value, this.refreshForm.bind(this))
    }

    $(this.storageUnitIdsTarget).select2({ theme: 'bootstrap4', width: '100%' })

    if (this.hasPaxtonUserIdTarget) {
      $(this.paxtonUserIdTarget).select2({ theme: 'bootstrap4', width: '100%' }).on('change', this.refreshForm.bind(this))
    }
  }

  disconnect() {
    if (this.cableConnection) {
      this.cableConnection.unsubscribe();
    }
  }

  refreshForm() {
    this.fetchLinkTarget.href += `?access_control_id=${this.accessControlIdTarget.value}&t=${Date.now()}`
    if (this.hasPaxtonUserIdTarget) {
      this.fetchLinkTarget.href += `&paxton_user_id=${this.paxtonUserIdTarget.value}`
    }
    this.fetchLinkTarget.click()
  }

  addUserToPaxton(e) {
    e.currentTarget.href = e.currentTarget.href + `?pin=${this.paxtonPinCodeTarget.value}`
  }
}
