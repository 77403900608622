import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'step',
    'locationId',
    'accessControlId',
    'storageUnitIds',
    'accessLevelId'
  ]

  connect() {
    const options = { theme: 'bootstrap4', width: '100%' }

    if (this.hasLocationIdTarget) {
      $(this.locationIdTarget)
        .select2(options)
        .on('change', () => { this.submitForm() })
    }

    if (this.hasAccessControlIdTarget) {
      $(this.accessControlIdTarget)
        .select2(options)
        .on('change', () => { this.submitForm() })
    }

    if (this.hasStorageUnitIdsTarget) {
      $(this.storageUnitIdsTarget)
        .select2(options)
        .on('change', () => { this.submitForm() })
    }

    if (this.hasAccessLevelIdTarget) {
      $(this.accessLevelIdTarget)
        .select2(options)
    }
  }

  submitForm() {
    this.element.dataset.turbo = true // prevent real submit, use turbolink
    this.element.querySelector('button[type="submit"]').click()
    this.element.dataset.turbo = false
  }
}
