import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    //*** Moved from html, users/form
    App.users.init_selection_form();
    $(".timeline_selection").select2({width: "100%"});
    $(".company_selection").select2({width: "100%"});
    $("#content .select2-search__field").removeAttr('style')
    //***

    //*** In case if errors present display correct tab
    const errorElement = this.element.querySelector('.error:not(.commentable-modal-error)')

    if (errorElement) {
      $('a.nav-link, .tab-pane').removeClass('active');
      const tabPaneElement = $(errorElement).closest('.tab-pane');
      tabPaneElement.addClass('show active');
      $('.nav.nav-tabs .nav-link[href="#' + tabPaneElement.attr('id') + '"]').addClass('active');
    }
    //***
  }
}
