import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const loadInterval      = 1000;
    let lastContentLoadedAt = new Date;
    // allow spec pass it presse buttons too fast
    lastContentLoadedAt.setSeconds(lastContentLoadedAt.getSeconds() - 1)

    $(this.element).on('hidden.bs.modal', (e) => {
      // Bootstrap does not support multiple modals on the page. In this case we have
      // scroll issue after close one of them. To solve it we need to check if global modal
      // still present on the page, if yes -- add `modal-open` class to the `body`,
      // other case (we closed global modal) remove entry content
      if ($(this.element).is(':visible')) {
        // https://stackoverflow.com/questions/28077066/bootstrap-modal-issue-scrolling-gets-disabled
        $('body').addClass('modal-open');
      } else {
        const turboFrame = e.currentTarget.querySelector('turbo-frame#root_modal')
        turboFrame.removeAttribute('src')
        turboFrame.innerHTML = "<div class=\"text-center\"><span class=\"loading\"></span></div>"
      }
    });

    //*** Detect when body dom change and reinit listeners
    // TODO: Maybe use: document.addEventListener('turbo:before-fetch-request', () => {...})
    const mutationObserver = new MutationObserver((a, b, c) => {
      // When we use `ReactRailsUJS.mountComponents()` it calls `mutationObserver` again
      // because of content was changed (Safari) and loop. To prevent such situation, update
      // only in case if content changed 1 second ago.

      if ((lastContentLoadedAt.getTime() + loadInterval) < (new Date).getTime()) {
        lastContentLoadedAt = new Date();

        App.helpers.refresh_listeners('#root_modal');
        ReactRailsUJS.mountComponents();
      }
    })

    // have the observer observe foo for changes in children
    mutationObserver.observe(
      this.element.querySelector('turbo-frame[id="root_modal"]'),
      { childList: true, subtree:true }
    )
    //***
  }
}
