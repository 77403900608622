// spinner-button
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['spinnerBtn']

  connect() {
    const cookieName  = this.spinnerBtnTarget.id
    const lastCheckAt = this.spinnerBtnTarget.dataset['lastCheckAt']
    const text        = this.spinnerBtnTarget.dataset['text']

    if (Cookies.get(cookieName) && lastCheckAt != '' && moment(Cookies.get(cookieName)) > moment(lastCheckAt)) {
      $(this.spinnerBtnTarget).html(
        `${$(this.spinnerBtnTarget).html()} <i class="fas fa-circle-notch fa-spin"></i>`
      ).addClass('disabled')
    } else {
      Cookies.remove(cookieName)
    }

    // Hook. Remote true doesn't work when we click on the `i` tag inside of link
    $(this.spinnerBtnTarget).find('i').click((e) => {
      e.preventDefault();
      this.spinnerBtnTarget.click()
    })

    this.spinnerBtnTarget.addEventListener('click', () => {
      const fiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000)
      Cookies.set(cookieName, new Date(), { expires: fiveMinutes })

      $(this.spinnerBtnTarget).html(
        `${$(this.spinnerBtnTarget).html()} <i class="fas fa-circle-notch fa-spin"></i>`
      ).addClass('disabled')
    })
  }
}
