import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      if (event.metaKey || event.ctrlKey) {
        return; // Don't open the modal if the user is trying to open the link in a new tab
      }
      $('#global-application-modal').modal('show');
    });
  }
}