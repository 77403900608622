import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-start", this.handleSubmit)
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-start", this.handleSubmit)
  }

  handleSubmit = (event) => {
    const form = event.target
    const searchParams = new URLSearchParams(new FormData(form))
    event.detail.fetchOptions.url = `${form.action}?${searchParams.toString()}`
  }
}

