import { Controller } from '@hotwired/stimulus'

/*
  STEPS:
    1 -- select location
    2 -- select access control
    3 -- select alarm
    4 -- select alarm zone / allow submit
*/
export default class extends Controller {
  static targets = ['locationId', 'accessControlId', 'alarmId', 'alarmZoneId', 'step', 'doorId',
                    'rentalStartDate', 'rentalEndDate', 'userIds']

  connect() {
    const options = { theme: 'bootstrap4', width: '100%' }

    $(this.locationIdTarget).select2(options)
                            .on('change', () => { this.setStep(1, 'accessControlIdTarget') })

    if (this.hasAccessControlIdTarget) {
      $(this.accessControlIdTarget)
        .select2(options)
        .on('change', () => { this.setStep(2, 'alarmIdTarget') })
    }

    if (this.hasAlarmIdTarget) {
      $(this.alarmIdTarget)
        .select2(options)
        .on('change', () => { this.setStep(3, 'alarmZoneIdTarget') })
    }

    if (this.hasAlarmZoneIdTarget) {
      $(this.alarmZoneIdTarget).select2(options)
    }

    if (this.hasDoorIdTarget) {
      $(this.doorIdTarget).select2(options)
    }

    if (this.hasUserIdsTarget) {
      $(this.userIdsTarget).select2(options)
    }
  }

  setStep(value, ...resetTargets) {
    const self = this
    resetTargets.forEach((target) => {
      if (self[`has${target.capitalize()}`]) { self[target].value = '' }
    })
    this.stepTarget.value = value
    this.submitForm()
  }

  validateRentalDate () {
    const startDate = this.rentalStartDateTarget.value
    const endDate   = this.rentalEndDateTarget.value

    if (startDate && endDate) {
      const diff = Math.round((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24))

      if (diff <= 0) {
        $(this.rentalStartDateTarget, this.rentalEndDateTarget).removeClass('is-warning').addClass('is-invalid')
      } else if (diff <= 5) {
        $(this.rentalStartDateTarget, this.rentalEndDateTarget).removeClass('is-invalid').addClass('is-warning')
      } else {
        $(this.rentalStartDateTarget, this.rentalEndDateTarget).removeClass('is-warning').removeClass('is-invalid')
      }
    }
  }

  submitForm() {
    this.element.dataset.turbo = true // prevent real submit, use turbolink
    this.element.querySelector('button[type="submit"]').click()
  }
}
