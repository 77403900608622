import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'commentText', 'commentCreatedAt' ]

  connect() {
    const self = this;

    this.form().on('submit', function(e) {
      if (self.commentTextTarget.value.split(' ').length < 3 && self.form().attr('data-turbo') !== 'true') {
        e.preventDefault();
        e.stopPropagation();

        self.modal().modal('show');
      } else {
        self.commentCreatedAtTarget.value = new Date();
      }
    });
  }

  onModalSaveClick() {
    if (this.commentTextTarget.value.split(' ').length < 3) {
      this.modal().find('small.error').removeClass('hide');
    } else {
      this.modal().modal('hide');
      // Can't use #submit() method for form, because sometimes we need to send turbo-frame request
      this.form().find('button[type="submit"]').click();
    }
  }

  form() {
    return $(this.element).closest('form');
  }

  modal() {
    return $(this.element).find('.modal')
  }
}
