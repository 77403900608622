import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectedTimeline', 'deleteUserUrl', 'deleteUserModal']

  initialize() {
    $(this.deleteUserModalTarget).appendTo('body')
  }

  connect() {
    $(this.selectedTimelineTarget)
      .select2({ theme: 'bootstrap4', width: '100%' })
      .on('change', this.refreshPath.bind(this))
  }

  refreshPath() {
    if (this.selectedTimelineTarget.value === '') {
      this.deleteUserUrlTarget.classList.add('disabled')
    } else {
      $(this.deleteUserUrlTarget).unbind('click')

      const timelineName = this.selectedTimelineTarget.selectedOptions[0].text;
      const options      = {
        title: I18n.t('global.confirm'),
        body: I18n.t('users.helpers.delete_confirmation_only_from_one_timeline', { name: `${this.deleteUserUrlTarget.dataset.userName} (${this.deleteUserUrlTarget.dataset.email})`, timeline_name: timelineName }),
        ok: I18n.t('users.helpers.delete_confirmation_from_timeline_btn', { timeline_name: timelineName }),
        cancel: I18n.t('global.cancel')
      };

      $(this.deleteUserUrlTarget).data('confirm', options); // Needs for `confirm_with_reveal.js`

      this.deleteUserUrlTarget.classList.remove('disabled')
      this.deleteUserUrlTarget.href = `${this.deleteUserUrlTarget.href.split('?')[0]}?from_all_timelines=false&selected_timeline=${this.selectedTimelineTarget.value}`
    }
  }
}
