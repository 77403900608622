import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  reloadPage = (event) => {
    if( $(event.target).find('#dark-mode').length > 0){
      window.location.reload();
    }
  }
  connect() {
    document.addEventListener("turbo:frame-render", this.reloadPage);
  }
  disconnect() {
    document.removeEventListener("turbo:frame-render", this.reloadPage);
  }
}
