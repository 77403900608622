import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'step',
    'locationId',
    'accessControlId',
    'storageUnitIds',
    'accessLevelId'
  ]

  connect() {
    const options = { theme: 'bootstrap4', width: '100%' }

    if (this.hasLocationIdTarget) {
      $(this.locationIdTarget)
        .select2(options)
        .on('change', () => {
          if (this.locationIdTarget.value)
            this.setStep(1)
          else
            this.setStep(0)
        })
    }

    if (this.hasAccessControlIdTarget) {
      $(this.accessControlIdTarget)
        .select2(options)
        .on('change', () => { this.setStep(2) })
    }

    if (this.hasStorageUnitIdsTarget) {
      $(this.storageUnitIdsTarget)
        .select2(options)
        .on('change', () => { this.setStep(3) })
    }

    if (this.hasAccessLevelIdTarget) {
      $(this.accessLevelIdTarget)
        .select2(options)
    }
  }

  setStep(value) {
    this.stepTarget.value = value
    this.submitForm()
  }

  submitForm() {
    this.element.dataset.turbo = true // prevent real submit, use turbolink
    this.element.querySelector('button[type="submit"]').click()
  }
}
